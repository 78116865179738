<template>
  <sims-layout>
    <div class="sims-incidents">
      <!-- All Incidents -->
      <div class="card">
        <div class="header">
          <div class="flex-wrapper">
            <div class="left">
              <h3>All Incidents</h3>
            </div>
            <div class="right">
              <a-button @click.prevent="$router.push('/sims/call-for-help')" type="primary">Call For Help</a-button>
            </div>
          </div>
        </div>
        <div class="body bb" v-if="!isLoading">
          <!-- Search -->
          <div class="search">
            <a-input-search
              style="max-width: 300px"
              placeholder="Search incidents"
              v-model="search_query"
            />
          </div>

          <!-- / Search -->
        </div>
        <div class="body">
          <!-- Loading -->
          <div class="loader" v-if="isLoadingCombined">
            <a-spin></a-spin>
          </div>
          <!-- / Loading -->

          <!-- No incidents -->
          <a-alert
            v-if="!isLoadingCombined && incidentsToShow.length == 0"
            type="info"
            message="No incidents to show"
          />
          <!-- / No incidents -->

          <!-- List -->
          <div class="list" v-if="!isLoadingCombined">
            <incident-list-item
              v-for="(incident, i) in incidentsToShowOrdered"
              :key="i"
              :incident="incident"
            ></incident-list-item>
          </div>
          <!-- / List -->
        </div>
      </div>
      <!-- / All Incidents -->
    </div>
  </sims-layout>
</template>

<script>
import simsLayout from "./simsLayout.vue";
import IncidentListItem from "../../components/sims/Incidents/IncidentListItem.vue";
import { mapGetters, mapActions } from "vuex";
import Incidents from "../../mixins/Incidents";
import Dates from "../../mixins/Dates";
import organisations from "../../helpers/organisations";
const _ = require("lodash");

export default {
  components: { simsLayout, IncidentListItem },
  mixins: [Incidents, Dates],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("incidents", {
      incidentsToShow: "incidentsToShow",
      incidentsToShowOrdered: "incidentsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },
  },
  methods: {
    ...mapActions("missionControl", {
      getOrganisationById: "getOrganisationById",
    }),

    ...mapActions("incidents", {
      loadIncidents: "loadIncidents",
      setSearchQuery: "setSearchQuery",
    }),

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getIncidentUrl(incident) {
      let organisation = this.getOrganisationById(incident.ownerId);
      let tenantId = organisations.getOrganisationTenantId(organisation);
      return "/sims/incidents/" + tenantId + "/" + incident.id;
    },
  },
};
</script>

<style scoped lang="scss">
.create-button {
  margin-top: 20px;
}

.flex-wrapper {
  display: flex;
  width: 100%;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>